<template>
  <div class="wrap-edit">
    <el-card>
      <FloatTitBtn :class="!sblxdisabled2?'wr-else':''">
        <div class="edit-header">
          <span>林业采伐申请表</span>
          <el-tooltip
              :disabled="!saving"
              popper-class="saving-tip"
              content="保存中"
              placement="left"
          >
            <div>
              <el-button type="primary" v-show="savebuttonshow" @click="onSubmit"
                         :loading="saving">{{ saving ? '' : '受理' }}
              </el-button>
            </div>
          </el-tooltip>
        </div>
      </FloatTitBtn>

      <div class="edit-container">
        <el-form
            ref="baseform"
            :label-position="labelPosition"
            :model="form"
            label-width="135px"
        >
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="总编号" class="asterisk" prop="ZBH">
                <el-input v-model.trim="form.ZBH" disabled></el-input>
              </el-form-item>
              <el-form-item label="所属分局" class="asterisk" prop="SSFJ">
                <el-select
                    class="input-width"
                    :disabled="sblxdisabled"
                    v-model.trim="form.SSFJ"
                    placeholder="请选择"
                >
                  <el-option
                      v-for="item in options1"
                      :disabled="sblxdisabled"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="案卷号" class="asterisk" prop="AJH">
                <el-input v-model.trim="form.AJH" disabled></el-input>
              </el-form-item>
              <el-form-item label="受理情况" class="asterisk" prop="SLQK">
                <el-select
                    class="input-width"
                    :disabled="sblxdisabled2"
                    v-model="form.SLQK"
                    placeholder="请选择"
                >
                  <el-option
                      v-for="item in options2"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="申报类型" class="asterisk" prop="SBLX">
                <el-select
                    filterable
                    @change="createzbh"
                    :disabled="isSblxDisabled"
                    class="input-width"
                    v-model.trim="form.SBLX"
                    placeholder="请选择"
                >
                  <el-option
                      v-for="item in options4"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="项目名称" style="display: none" class="asterisk">
                <el-input
                    v-model.trim="form.XMMC"
                    :disabled="sblxdisabled"
                ></el-input>
              </el-form-item>
              <el-form-item label="申请单位" style="display: none" class="asterisk" >
                <el-input
                    v-model.trim="form.JSDW"
                    :disabled="sblxdisabled"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="所属乡镇" prop="XZQHDM">
                <el-select
                    filterable
                    :disabled="sblxdisabled"
                    clearable
                    class="input-width"
                    v-model.trim="form.XZQHDM"
                    placeholder="请选择"
                >
                  <el-option
                      v-for="item in options5"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="地址" class="asterisk" prop="JSWZ">
                <el-input
                    v-model.trim="form.JSWZ"
                    :disabled="sblxdisabled"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="申请人" class="asterisk" prop="LXR">
                <el-input
                    v-model.trim="form.LXR"
                    :disabled="sblxdisabled"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="手机号" class="asterisk" prop="SJH">
                <el-input
                    v-model.trim="form.SJH"
                    :disabled="sblxdisabled"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="邮件" style="display: none" >
                <el-input
                    v-model.number="form.DZYJ"
                    :disabled="sblxdisabled"
                    @blur="checkEmail"

                >
                </el-input>
              </el-form-item>
              <el-form-item label="伐区总面积">
                <el-input
                    v-model="agroForm.FQZMJ"
                     onkeyup="value=value.replace(/[^\d.]/g,'').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^\./g, '')" 
                >
                  <template #append>亩</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="电话" style="display: none" >
                <el-input v-model.trim="form.DH" :disabled="sblxdisabled"></el-input>
              </el-form-item>
              <el-form-item label="采伐面积">
                <el-input onkeyup="value=value.replace(/[^\d.]/g,'').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^\./g, '')" v-model="agroForm.CFMJ">
                  <template #append>亩</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="采伐蓄积(总量)">
                <el-input v-model.trim="agroForm.CFXJ"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item style="display: none" label="通讯地址" >
                <el-input v-model.trim="form.DZ" :disabled="sblxdisabled"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="法人代表" style="display: none" >
                <el-input
                    v-model.trim="form.FRDB"
                    :disabled="sblxdisabled"
                ></el-input>
              </el-form-item>
              <el-form-item label="证件名称" style="display: none" >
                <el-select
                    class="input-width"
                    :disabled="sblxdisabled"
                    v-model.trim="form.ZJMC"
                    placeholder="请选择"
                >
                  <el-option-group
                      v-for="group in options3"
                      :key="group.label"
                      :label="group.label"
                  >
                    <el-option
                        v-for="item in group.options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>
              <el-form-item label="接件人" prop="JJR">
                <el-input v-model.trim="form.JJRXM" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="代理人" style="display: none" >
                <el-input
                    v-model.trim="form.DLR"
                    :disabled="sblxdisabled"
                ></el-input>
              </el-form-item>
              <el-form-item label="证件号码" style="display: none" >
                <el-input
                    v-model.trim="form.ZJHM"
                    :disabled="sblxdisabled || !form.ZJMC"
                ></el-input>
              </el-form-item>
              <el-form-item label="接件日期" prop="JJRQ">
                <el-date-picker
                    :disabled="sblxdisabled"
                    style="width: 100%"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    v-model="form.JJRQ"
                    placeholder="请选择接件日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注" prop="BZ">
                <el-input
                    v-model.trim="form.BZ"
                    :disabled="sblxdisabled"
                    type="textarea"
                    :rows="3"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>
  </div>
</template>
<script>
import {ElMessage} from "element-plus";
import {toRaw} from "vue";
import mgr from "@/services/security.js";
import FloatTitBtn from "@/components/FloatTitBtn";

export default {
  name: 'ForestryCut',
  components: {
    FloatTitBtn,
  },
  data() {
    return {
      labelPosition: "left",
      form: {
        Id: "",
        ZBH: "",
        AJH: "",
        SSFJ: "",
        SLQK: true,
        SBLX: "",
        JSDW: "",
        XMDM: "",
        XMMC: "",
        XZQHDM: "",
        JSWZ: "",
        LXR: "",
        SJH: "",
        DZYJ: "",
        DH: "",
        DZ: "",
        FRDB: "",
        DLR: "",
        ZJMC: "",
        ZJHM: "",
        JJRXM: "",
        JJRQ: new Date(),
        BZ: "",
      },
      agroForm: {
        BJID: null,
        FQZMJ:0,
        CFMJ:0,
        CFXJ:''
      },
      saving: false,
      options1: [],
      options2: [
        {
          value: true,
          label: "受理",
        },
        {
          value: false,
          label: "不受理",
        },
      ],
      options3: [],
      options4: [],
      options5: [],
      sblxdisabled: false,
      sblxdisabled2: true,
      isSblxDisabled: true,
      savebuttonshow: true,
      restaurants: [],
    };
  },
  methods: {
    onlyNum(){
      this.agroForm.FQZMJ=this.agroForm.FQZMJ.replace(/[^\d.]/g,'').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^\./g, '')
      this.agroForm.CFMJ=this.agroForm.CFMJ.replace(/[^\d.]/g,'').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^\./g, '')
    },
    async getlssytdsq() {
      let id = this.$route.query.instanceId
      if(id){
        let res = await this.$ajax.get(
            this.$appConfig.apiUrls.ywxtApi + "/api/YW/getlycfsq?BJID=" + id,
        );
        if(res.data.Data){
          this.agroForm = res.data.Data[0]
        }
      }else {
        this.agroForm.BJID = null
        this.agroForm.FQZMJ = 0
        this.agroForm.CFMJ = 0
        this.agroForm.CFXJ = ''
      }
    },
    async reviseInfo() {
      this.agroForm.bjid = this.form.Id;
      this.onlyNum();
      await this.$ajax.post(
          this.$appConfig.apiUrls.ywxtApi + "/api/YW/addorupdatenycfsq", this.agroForm
      );
    },
    //获取项目代码数据
    async getXmmc(queryString, cb) {
      let me = this;
      await this.$ajax
          .get(me.$appConfig.apiUrls.ywxtApi + "/api/YW/SearchXMDM?xmdm=" + queryString)
          .then(function (response) {
            if (response.data.IsSuccess) {
              let res = response.data.Data;
              let arr = []
              if (res != null) {
                for (let a = 0; a < res.length; a++) {
                  let speak = {
                    XMMC: "",
                    JSDW: "",
                    XZQHDM: "",
                    JSWZ: "",
                  }
                  for (let k1 in speak) {
                    for (let k2 in res[a]) {
                      if (k1 === k2) {
                        speak[k1] = res[a][k2]
                      }
                    }
                  }
                  speak.value = res[a].XMDM
                  speak.JSWZ = res[a].XMZL
                  //拿到form值  赋值
                  arr.push(speak)
                }
              }
              me.restaurants.value = arr
              var $cname = document.querySelector(".cname");
              me.restaurants.value.length > 0 ? ($cname.style.display = "block") : "";
              let results = queryString
                  ? me.restaurants.value.filter(me.createFilter(queryString))
                  : me.restaurants.value
              // console.log('获取', results);
              cb(results)
            } else {
              console.log(response.data.errorMessage);
            }
          });
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
            restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
            0
        )
      }
    },
    handleSelect(item) {
      for (let k1 in this.form) {
        for (let k2 in item) {
          if (k1 === k2) {
            this.form[k1] = item[k2]
          }
        }
      }
    },
    async onSubmit() {
      let me = this;
      if (me.checkform()) {
        this.saving = true;
        try {
          await this.$ajax
              .post(me.$appConfig.apiUrls.ywxtApi + "/api/YW/Addlahz", me.form)
              .then((response) => {
                if (response.data.IsSuccess) {
                  me.form.Id = response.data.Data.instanceId;
                  me.$store.state.AJH = me.form.AJH
                  me.$store.state.XMDM = me.form.XMDM
                  me.reviseInfo()
                  ElMessage({
                    type: "success",
                    message: "保存成功",
                  });
                  if (!me.$route.query.taskId) {
                    me.$router.handleClose(me.$route)
                  }

                  const row = JSON.stringify({
                    SBLX: this.form.SBLX,
                    Id: this.form.Id,
                    taskid: response.data.Data.taskId,
                    ZBH: this.form.ZBH
                  })
                  if (!me.$route.query.taskId) {
                    this.$router.push({path: '/buslist/flowinfo', query: {row}})
                  }
                } else {
                  ElMessage({
                    type: "info",
                    message: "立案失败!",
                  });
                  console.log(response.data.errorMessage);
                }
              });
        } catch (error) {
          console.log(error);
        } finally {
          this.saving = false;
        }
      }
    },
    updateSBLX() {
      this.$nextTick(() => {
        let id = toRaw(this.form.SBLX)
        if (this.$route.query.Id != undefined && this.$route.query.Id != null) {
          if (id != this.$route.query.Id) {
            this.$refs['baseform'].resetFields();
          }
          this.form.SBLX = this.$route.query.Id
          this.createzbh(this.form.SBLX)
        }
      });
    },
    //转化json
    switch() {
      let me = this;
      this.$ajax
          .get(me.$appConfig.apiUrls.flowApi + "/api/WorkFlow/GetWorkFlowList")
          .then(function (response) {
            if (response.data.IsSuccess) {
              for (var key in response.data.Data) {
                me.options4.push({value: key, label: response.data.Data[key]});
              }
            } else {
              console.log(response.data.errorMessage);
            }
          });

      me.form.Id = this.$route.query.instanceId;
      if (me.form.Id === undefined) {
        me.sblxdisabled = false;
        me.sblxdisabled2 = false;
        if (me.$route.query.Id == undefined || me.$route.query.Id == null) {
          me.isSblxDisabled = false;
        }
        me.savebuttonshow = true;
        me.form.Id = "";
      } else if(me.$route.query.taskId){
        me.sblxdisabled = false;
        me.savebuttonshow = true;
      } else {
        me.sblxdisabled = true;
        me.savebuttonshow = false;
      }
      this.$ajax
          .get(me.$appConfig.apiUrls.ywxtApi + "/api/YW/Getlahz?Id=" + me.form.Id)
          .then(function (response) {
            me.$store.commit('getFormData', response.data.Data)
            if (response.data.IsSuccess) {
              me.form = response.data.Data;
              me.$store.state.AJH = me.form.AJH
              me.$store.state.XMDM = me.form.XMDM
              if (me.$route.query.Id != undefined && me.$route.query.Id != null) {
                me.form.SBLX = me.$route.query.Id
                me.createzbh(me.form.SBLX)
              }
              me.$ajax
                  .post(me.$appConfig.apiUrls.flowApi + "/api/WorkFlowStep/GetBeforeSteps?taskId=" + me.$route.query.taskId)
                  .then(function (response) {
                    if (response.data.IsSuccess) {
                      if (response.data.Data.length === 0 && me.$store.state.userInfo.UserId === me.form.JJR) {
                        me.sblxdisabled = false;
                        me.savebuttonshow = true;
                      }
                    } else {
                      console.log(response.data.errorMessage);
                    }
                  });
            } else {
              ElMessage({
                type: "info",
                message: "初始化表单失败",
              });
            }
          });
    },
    checkform() {
      let b = true;
      let f = this.form;
      if (b) b = this.checkinput(f.ZBH, "总编号");
      if (b) b = this.checkinput(f.AJH, "案卷号");
      if (b) b = this.checkinput(f.SSFJ, "所属分局");
      if (b) b = this.checkinput(f.SBLX, "申报类型");
      if (this.$appConfig.checkxmdm.includes(f.SBLX)) {
        // if (b) b = this.checkinput(f.XMDM, "项目代码");
      }
      // if (b) b = this.checkinput(f.XMMC, "项目名称");
      // if (b) b = this.checkinput(f.JSDW, "建设单位");
      if (b) b = this.checkinput(f.JSWZ, "地址");
      if (b) b = this.checkinput(f.LXR, "申请人");
      if (b) b = this.checkinput(f.SJH, "手机号");

      if (b && f.ZJHM) b = this.checkID(f.ZJHM);
      if (b && f.DZYJ) b = this.checkEmail(f.DZYJ)
      return b;
    },
    checkinput(val, mess) {
      if (!val || val.trim().length < 1) {
        ElMessage({
          type: "info",
          message: "请输入" + mess,
        });
        return false;
      } else {
        return true;
      }
    },
    async getssfj() {
      let me = this;
      await this.$ajax
          .get(me.$appConfig.apiUrls.ywxtApi + "/api/Dictionary/GetDictionary?code_class=1")
          .then(function (response) {
            if (response.data.IsSuccess) {
              response.data.Data.forEach(function (value) {
                me.options1.push({value: value.code, label: value.code_value});
              });
            } else {
              console.log(response.data.errorMessage);
            }
          });
    },
    async getxzqh() {
      let me = this;
      await this.$ajax
          .get(me.$appConfig.apiUrls.ywxtApi + "/api/Dictionary/GetDictionary?code_class=3")
          .then(function (response) {
            if (response.data.IsSuccess) {
              response.data.Data.forEach(function (value) {
                me.options5.push({value: value.code, label: value.code_value});
              });
            } else {
              console.log(response.data.errorMessage);
            }
          });
    },
    async getuser() {
      let user = await mgr.getUser();
      this.form.JJRXM = user.profile.name;
    },
    createzbh(val) {
      let me = this;
      this.$ajax
          .get(
              me.$appConfig.apiUrls.flowApi +
              "/api/WorkFlowExecute/GetNextInstanceNo?flowId=" +
              val
          )
          .then(function (response) {
            if (response.data.IsSuccess) {
              me.form.ZBH = response.data.Data;
            } else {
              console.log(response.data.errorMessage);
            }
          });
      this.$ajax
          .get(
              me.$appConfig.apiUrls.ywxtApi +
              "/api/Tool/GetNextNumber?ruleId=AJH-" +
              val
          )
          .then(function (response) {
            if (response.data.IsSuccess) {
              me.form.AJH = response.data.Data;
            } else {
              console.log(response.data.errorMessage);
            }
          });
    },
    async getzjlx() {
      let me = this;
      await this.$ajax
          .get(me.$appConfig.apiUrls.ywxtApi + "/api/Dictionary/Getzjlx")
          .then(function (response) {
            if (response.data.IsSuccess) {
              me.options3 = response.data.Data
            } else {
              console.log(response.data.errorMessage);
            }
          });
    },
    checkMobile() {
      const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
      if (regMobile.test(this.form.SJH)) {
        return true
      }
      ElMessage({
        type: "info",
        message: "请输入正确的手机号",
      });
      return false
    },
    checkEmail() {
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
      if (regEmail.test(this.form.DZYJ)) {
        return true
      }
      ElMessage({
        type: "info",
        message: "请输入正确的邮箱",
      });
      return false
    },
    checkID() {
      let regID = /^\d{5,}$/
      if (this.form.ZJMC === "104") {
        regID = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      }
      if (regID.test(this.form.ZJHM)) {
        return true
      } else {
        ElMessage({
          type: "info",
          message: "请输入正确的证件号码",
        });
        return false
      }
    }
  },
  activated() {
    this.updateSBLX()
    this.getlssytdsq()
  },
  mounted() {
    this.switch();
    this.getssfj();
    this.getxzqh()
    this.getzjlx()
    this.getlssytdsq()
    this.restaurants.value = this.loadAll
  },
  beforeRouteLeave(to, from) {
    if (this.$router.closeTag && this.$router.closeTag.href === from.href) {
      // console.log('执行了关闭当前的');
      this.$refs['baseform'].resetFields();
      this.$router.closeTag = {};
      this.form.Id = ''
    }
  },
};
</script>

<style scoped>
.wr-else {
  width: calc(100% - 76px);
}

.edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el-form {
  width: 100%;
  overflow: hidden;
}

.input-width {
  width: 100%;
}

.wrap-edit {
  height: 100%;
  overflow-y: scroll;
}

.wrap-edit .edit-header {
  margin-bottom: 15px;
}

:deep(.el-autocomplete) {
  width: 100%;
}

:deep(.el-form--label-left .asterisk .el-form-item__label)::after {
  content: '*';
  color: red;
}
</style>
<style>
.cname {
  display: none;
  transition: all 0.3s ease 0s;
}

.saving-tip {
  background: #edb868 !important;
}

.saving-tip .el-popper__arrow::before {
  background: #edb868 !important;
}
</style>
